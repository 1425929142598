import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

function swiperProgramsInit() {
  const programsCarousels = document.querySelectorAll(
    '.programs-slider__carousel'
  );

  if (0 < programsCarousels.length) {
    programsCarousels.forEach((programsCarousel) => {
      const slides = programsCarousel.querySelectorAll('.programs-slider__col');

      if (0 < slides.length) {
        slides.forEach((slide) => {
          slide.classList.add('swiper-slide');
        });
      }

      const swiper = new Swiper(programsCarousel, {
        spaceBetween: 16,
        preloadImages: false,
        lazy: true,
        pagination: {
          type: 'progressbar',
          el: programsCarousel.querySelector('.programs-slider__progressbar'),
          clickable: true,
          hiddenClass: 'swiper-pagination-hidden',
        },
        navigation: {
          prevEl: programsCarousel.querySelector('.programs-slider__prev'),
          nextEl: programsCarousel.querySelector('.programs-slider__next'),
          hiddenClass: 'swiper-navigation-hidden',
        },

        breakpoints: {
          200: {
            slidesPerView: 1.2,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 1.9,
          },
          1023: {
            slidesPerView: 2.2,
          },
          1400: {
            slidesPerView: 2.8,
          },
          1680: {
            slidesPerView: 3.5,
          },
        },
      });

      let slideNumber = swiper.realIndex + 1;
      const slideTotalNumber = swiper.snapGrid.length;

      if (
        programsCarousel.querySelector(
          '.swiper-cards__navigation .programs-slider__fraction'
        )
      ) {
        programsCarousel.querySelector(
          '.swiper-cards__navigation .programs-slider__fraction'
        ).innerHTML = '0' + slideNumber + ' / ' + '0' + slideTotalNumber;

        swiper.on('slideChange', function () {
          slideNumber = swiper.realIndex + 1;
          programsCarousel.querySelector(
            '.swiper-cards__navigation .programs-slider__fraction'
          ).innerHTML = '0' + slideNumber + ' / ' + '0' + slideTotalNumber;
        });
      }

      document.addEventListener('facetwp-loaded', function () {
        swiper.init();
      });
    });
  }
}

domReady(() => {
  swiperProgramsInit();
});

document.addEventListener('facetwp-loaded', function () {
  swiperProgramsInit();
});
